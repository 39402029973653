<template>
<img class="soccer" alt="Sign Up" :src="require('@/assets/soccer_field.png')" />
<h1>Sign Up</h1>
<div class="register">
    <input type="text" placeholder="Enter Nombre" />
    <input type="text" placeholder="Enter Email" />
    <input type="text" placeholder="Enter Pass" />
    <button>Sign Up</button>
</div>  

</template>


<script>
export default {
name: 'SignUp',
};
</script>

<style>
.soccer{
    width:200px
}
.register input{
    width:300px;
    height:40px;
    padding-left: 20px;
    border: 1px solid skyblue;
    display: block;
    margin-bottom: 30px;
    margin-right: auto;
    margin-left: auto;
}
.register button{
    width: 320px;
    height:40px;
    border: 1px solid skyblue;
    background: skyblue;
    color: #fff;
}   
</style>
